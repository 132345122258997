.tickets-page-content {
    display: flex;
    flex-direction: column;
    padding-left: 32px;
    padding-right: 32px;
    align-items: center;
  }

  .clear-filters-button {
    margin-bottom: 16px;
  }

  .show-test-record {
    display: flex;
    margin-bottom: 16px;
  }

.centered-checkbox {
  display: flex;
  align-self: center;
}

  .filters-space {
    padding: 8px;
  }

  .tickets-page-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    height: 80vh;
  }

  .table-row-1:hover {
    background-color: #ffebeb;
  }

  .table-row-2:hover {
    background-color: #f8f9ff;
  }

  .table-row-3:hover {
    background-color: #f3fff4;
  }