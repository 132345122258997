.small-text-table .ant-table-cell {
    font-size: 14px; /* Зменшення розміру тексту */
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .custom-table th,
  .custom-table td {
    border: 1px solid #ddd;
    padding: 2px;
    text-align: center;
    width: 10%;
  }
  
  .custom-table th {
    background-color: #BDD7EE;
  }
  
  .custom-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .custom-table td {
    font-size: 12px;
  }


  .custom-table td.percent-cell {
    background: linear-gradient(90deg, green, red);
  }
